import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Button,
    SimpleGrid
} from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import BackSpace from '../Icon/BackSpace'
import Points from './Points'
import { DiscountProps } from './types'
import usePin from './usePin'

const buttonWidth = { base: '65px', sm: '80px', md: '90px' }

export default function ModalPin({
    element,
    title,
    onDone,
    validate,
    adminPin
}: DiscountProps) {
    const {
        isOpen,
        setOpen,
        openHandler,
        numbers,
        clearHandler,
        removeLastDigitHandler,
        digitHandler,
        pinValues,
        okHandler,
        isLoading
    } = usePin(onDone, validate, adminPin)

    return (
        <>
            {element(openHandler)}
            <Modal
                isCentered={true}
                size="lg"
                isOpen={isOpen}
                closeOnOverlayClick={!isLoading}
                onClose={() => setOpen(false)}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody py="10" px="12">
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb="2"
                        >
                            {title}
                        </Text>
                        <Text
                            mb="6"
                            textAlign="center"
                            color="gray-2"
                            fontSize="sm"
                        >
                            {adminPin
                                ? 'Ingresa un PIN válido para continuar'
                                : 'Ingresa tu pin para realizar pedido'}
                        </Text>
                        <Points values={pinValues} />
                        <Box display="flex" justifyContent="space-around">
                            <Box textAlign="center">
                                <SimpleGrid gap="3" columns={3}>
                                    {numbers.map((number: number) => (
                                        <Button
                                            key={number}
                                            w={buttonWidth}
                                            variant="gray-3:2xl-lg"
                                            bg="transparent"
                                            color="gray-2"
                                            onClick={() => digitHandler(number)}
                                        >
                                            {number}
                                        </Button>
                                    ))}
                                </SimpleGrid>
                                <Button
                                    mt="3"
                                    w={buttonWidth}
                                    variant="gray-3:2xl-lg"
                                    bg="transparent"
                                    color="gray-2"
                                    onClick={() => digitHandler(0)}
                                >
                                    0
                                </Button>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gridGap="3"
                            >
                                <Button
                                    w={buttonWidth}
                                    variant="orange:2xl-lg"
                                    onClick={removeLastDigitHandler}
                                    leftIcon={<BackSpace fill={colors.white} />}
                                />
                                <Button
                                    w={buttonWidth}
                                    variant="orange:2xl-lg"
                                    onClick={clearHandler}
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant="alo-green:2xl-lg"
                                    onClick={okHandler}
                                    isLoading={isLoading}
                                    w={buttonWidth}
                                >
                                    Ok
                                </Button>
                            </Box>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
