import React, { useState, ChangeEvent } from 'react'
import { Switch, useToast } from '@chakra-ui/react'
import { put } from '../../lib/http'
import User from '../../models/user'
import { controlList } from './storage'

export default function SwitchEnabled({ user }: { user: User }) {
    const [checked, setChecked] = useState(() => user?.enabled ?? true)

    const toast = useToast()

    async function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const enabled = ev.target.checked
        setChecked(enabled)
        const { error } = await put(`/api/users/${user.id}/enabled`, {
            enabled
        })
        if (error) {
            setChecked(!enabled)
            toast({
                title: 'Hubo problemas al actualizar, vuelva a intentar',
                status: 'error',
                position: 'top',
                isClosable: true
            })
        } else {
            toast({
                title: enabled
                    ? 'Se ha habilitado el usuario'
                    : 'Se ha deshabilitado el usuario',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            controlList.load()
        }
    }

    return (
        <>
            <Switch
                colorScheme="green"
                size="md"
                isChecked={checked}
                onChange={changeHandler}
            />
        </>
    )
}
