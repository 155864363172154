import { Button, useToast } from '@chakra-ui/react'
import CashIcon from '../../../../../components/Icon/CashIcon'
import ModalWaiters from '../../../modals/ModalWaiters'
import { useState } from 'react'
import useOrderByTable from '../../../hooks/useOrderByTable'
import useSaveOrderByTable from '../../../hooks/useSaveOrderByTable'
import useSession from '../../../../../hooks/useSession'
import ModalPin from '../../../modals/ModalPin'
import useCashbox from '../../../hooks/useCashbox'
import ModalAnnul from '../../../modals/ModalAnnul'
import useMenu from '../../../../../hooks/useMenu'
import useStorage from '../../../../../hooks/useStorage'

export default function ButtonSaveOrder() {
    const toast = useToast()
    const { getPriceTotal, menu, initialMenu } = useMenu()
    const [isOpenWaiters, setIsOpenWaiters] = useState(false)
    const [isOpenPin, setIsOpenPin] = useState(false)
    const [isOpenAnnul, setIsOpenAnnul] = useState(false)
    const { orderId, pin } = useOrderByTable()
    const { update, register } = useSaveOrderByTable()
    const { cashboxUser } = useCashbox()
    const { isAdmin, isCashier, isLogged, isDealer, isWaiter, session } =
        useSession()

    const isDisabled = menu.filter(item => item.quantity > 0).length === 0

    const { business_variables } = useStorage()
    const cashier_as_waiter = business_variables.find(
        v => v.code === '007'
    )?.value

    const hasItemsReduced = () =>
        initialMenu.find(
            initMenuItem =>
                (menu.find(menuItem => menuItem.itemId === initMenuItem.itemId)
                    ?.quantity as number) < initMenuItem.quantity
        )

    function clickHandler(reason?: string) {
        /**
         * Si existe la orden, si no está logeado, tiene que estar abierta la caja
         */
        if (orderId && !isLogged && !cashboxUser) {
            const hasItemsAnnulled = initialMenu.find(
                initMenuItem =>
                    (menu.find(
                        menuItem => menuItem.itemId === initMenuItem.itemId
                    )?.quantity as number) < initMenuItem.quantity
            )

            if (hasItemsAnnulled) {
                toast({
                    title: 'La caja no se encuentra aperturada para este usuario',
                    status: 'error',
                    position: 'top'
                })
                return
            }
        }
        /**
         * Si es Mozo sin sesión, le pedirá el pin
         * en caso ya  haya ingresado el pin con anterioridad, ya no le va pedir
         */
        if (!isLogged && !pin) {
            setIsOpenPin(true)
            return
        }

        if (orderId) {
            update(reason)
        } else {
            // Si es un mozo logeado, podrá defrente confirmar el pedido
            if (isWaiter() || (isCashier() && cashier_as_waiter === '1')) {
                register(session?.user.id)
            } else {
                setIsOpenWaiters(true)
            }
        }
    }

    return (
        <>
            {(isAdmin() ||
                (isCashier() && cashier_as_waiter === '0') ||
                isDealer()) && (
                <ModalWaiters
                    isOpen={isOpenWaiters}
                    onClose={() => setIsOpenWaiters(false)}
                />
            )}
            <ModalPin
                isOpen={isOpenPin}
                onClose={() => setIsOpenPin(false)}
                onPin={register}
            />
            <ModalAnnul
                isOpen={isOpenAnnul}
                onClose={() => setIsOpenAnnul(false)}
                annulOrder={false}
                onDone={form => {
                    setIsOpenAnnul(false)
                    clickHandler(form.reason)
                }}
            />
            <Button
                colorScheme="green"
                size="lg"
                fontSize={{
                    xl: 'md',
                    lg: 'sm'
                }}
                w="full"
                leftIcon={<CashIcon fill="white" fontSize={32} />}
                onClick={() =>
                    hasItemsReduced() ? setIsOpenAnnul(true) : clickHandler()
                }
                isDisabled={isDisabled}
            >
                {orderId ? 'Actualizar pedido' : 'Confirmar pedido'} (S/{' '}
                {getPriceTotal().toFixed(2)})
            </Button>
        </>
    )
}
