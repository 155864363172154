import { Box, Fade, Flex, SimpleGrid, Spinner } from '@chakra-ui/react'
import BoxItem from './BoxItem'
import useListColors from '../../../../hooks/useListColors'
import { ItemData } from '../../../../context/MenuContext'
import { useEffect, useState } from 'react'
import { get } from '../../../../lib/http'
import { ListItemsProps } from './types'
import useMenu from '../../../../hooks/useMenu'

export default function ListItems({ categoryItemId }: ListItemsProps) {
    const { getQuantity, addItem } = useMenu()
    const { get: getColor } = useListColors()
    const [isLoading, setIsLoading] = useState(true)
    const [categoryItems, setCategoryItems] = useState<ItemData[]>([])

    async function load() {
        if (!categoryItemId) return
        setIsLoading(true)
        const result = await get<ItemData[]>(
            `/api/items?category_item_id=${categoryItemId || '0'}`
        )
        if (result.data) {
            setCategoryItems(result.data.result)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        load()
    }, [categoryItemId])

    return (
        <Box
            w="full"
            p={{
                base: '4',
                lg: '5'
            }}
            bg="white"
            maxH={{
                base: 'calc(100vh - 260px)',
                lg: 'calc(100vh - 60px)'
            }}
            overflow="auto"
        >
            {isLoading && (
                <Flex justify="center" pt={10}>
                    <Spinner />
                </Flex>
            )}
            {!isLoading && (
                <Fade in>
                    <SimpleGrid
                        spacing={3}
                        minChildWidth={{
                            sm: '150px',
                            xl: '160px'
                        }}
                    >
                        {categoryItems.map((item, i) => (
                            <BoxItem
                                key={i}
                                bg={getColor()}
                                quantity={getQuantity(item.id)}
                                onClick={() => addItem(item)}
                                name={item.name}
                                imageUrl={item.image_url}
                                price={item.price}
                                hasInventoryControl={item.has_inventory_control}
                                stock={item.stock}
                                hasStock={
                                    !(
                                        item.has_inventory_control &&
                                        item.stock <= 0
                                    ) // por consultar
                                }
                            />
                        ))}
                    </SimpleGrid>
                </Fade>
            )}
            {categoryItems.length === 0 && !isLoading && (
                <Box textAlign="center" color="gray-3">
                    No existen resultados
                </Box>
            )}
        </Box>
    )
}
