import { render, renders, useRender } from 'redity'
import { generateId } from '../../utilities/generateId'
import { get } from '../http'
import Pagination from '../../types/Pagination'
import { parse } from 'fter'
import { ControlListContent, ListQuery } from './types'
import { ChangeEvent } from 'react'

export default class ControlList<T> {
    private paths: Record<string, string> = {}
    readonly KeyMain = generateId()
    readonly Indexs = {
        BODY: 'BODY',
        PAGINATION: 'PAGINATION',
        HEADER: 'HEADER',
        SEARCH: 'SEARCH',
        DATA: 'DATA'
    }
    loading = false
    pagination: Pagination = {
        total: 0,
        page: 1,
        pages: 1
    }

    private quieries: ListQuery = {
        limit: 10,
        page: 1,
        order: 'asc',
        sort: 'id',
        search: ''
    }

    content: ControlListContent
    data: T[] = []

    constructor(content: ControlListContent) {
        this.content = {
            removeLimit: false,
            ...content
        }
    }

    setData = (data: T[]) => {
        this.data = data
        this.render()
    }

    render = (index?: string) => {
        if (index) {
            render(this.KeyMain, index)
        } else {
            renders(this.KeyMain)
        }
    }

    load = async () => {
        if (this.content.removeLimit) {
            this.quieries.limit = 0
        }

        let url = parse(this.quieries, this.content.path)
        Object.entries(this.paths).forEach(([key, value]) => {
            url = url.replace(`{${key}}`, value)
        })

        const { error, data } = await get<T[]>(url)

        if (error) {
            return false
        }

        this.data = data.result
        if (data.pagination) {
            this.pagination = data.pagination
        }
        renders(this.KeyMain)
        return true
    }

    setQuery = <K extends keyof ListQuery>(key: K, value: ListQuery[K]) => {
        this.quieries[key] = value
    }

    deleteQuery = (key: string) => {
        delete this.quieries[key]
    }

    getQuery = (key: string) => {
        return this.quieries[key] || null
    }

    setPath = (key: string, value: string) => {
        this.paths[key] = value
    }

    getPath = (key: string) => {
        return this.paths[key]
    }

    usePagination() {
        useRender(this.KeyMain, this.Indexs.PAGINATION)
        const onPrev = async () => {
            if (this.quieries.page === 1) return false
            this.pagination.page = this.pagination.page - 1
            render(this.KeyMain, this.Indexs.PAGINATION)
            this.quieries.page = this.quieries.page - 1
            return this.load()
        }

        const onNext = async () => {
            if (this.quieries.page === this.pagination.pages) return false
            this.pagination.page = this.pagination.page + 1
            render(this.KeyMain, this.Indexs.PAGINATION)
            this.quieries.page = this.quieries.page + 1
            return this.load()
        }

        const onPage = async (page: number) => {
            if (page === this.pagination.page) return false
            if (page > this.pagination.pages) return false
            this.pagination.page = page
            render(this.KeyMain, this.Indexs.PAGINATION)
            this.quieries.page = page
            return this.load()
        }

        const onLimit = async (limit: string) => {
            if (parseInt(limit) === this.quieries.limit) return false
            this.quieries.limit = parseInt(limit)
            render(this.KeyMain, this.Indexs.PAGINATION)
            return this.load()
        }

        return {
            queries: this.quieries,
            onPrev,
            onNext,
            onPage,
            nextActive: this.pagination.page < this.pagination.pages,
            prevActive: this.pagination.page !== 1,
            pagination: this.pagination,
            onLimit
        }
    }

    useSearch() {
        const r = useRender(this.KeyMain, this.Indexs.SEARCH)

        const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
            this.quieries.page = 1
            this.quieries.search = ev.target.value
            this.load()
            r()
        }

        const clear = () => {
            this.quieries.search = ''
            this.load()
            r()
        }

        return {
            value: this.quieries.search,
            onChange: changeHandler,
            clear
        }
    }

    useFilter(keyName: string) {
        const r = useRender(this.KeyMain, keyName)
        const changeHandler = (
            ev: ChangeEvent<HTMLSelectElement | HTMLInputElement>
        ) => {
            const v = ev.target.value
            this.quieries.page = 1
            if (v.toString()) {
                this.setQuery(keyName, v)
            } else {
                this.deleteQuery(keyName)
            }
            this.load()
            r()
        }

        const value = (this.quieries[keyName] || '') as string

        return {
            onChange: changeHandler,
            value
        }
    }

    useData() {
        useRender(this.KeyMain, this.Indexs.DATA)
        return this.data
    }
}
