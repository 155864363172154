import { useEffect, useState } from 'react'
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import GridBookings from './GridPreOrders'
import { ModalPreOrdersProps } from './types'
import PreOrder from '../../../../models/pre_order'
import usePreOrder from '../../hooks/usePreOrder'
import useMenu from '../../../../hooks/useMenu'
import { MenuItem } from '../../../../context/MenuContext'
import itemHash from '../../../../utilities/item-hash'

export default function ModalPreOrder(props: ModalPreOrdersProps) {
    const { preOrders: savedPreOrders, setPreOrders: savePreOrders } =
        usePreOrder()
    const [preOrders, setPreOrders] = useState<PreOrder[]>([])
    const { setMenu } = useMenu()

    useEffect(() => {
        if (!props.isOpen) return
        setPreOrders(savedPreOrders)
    }, [props.isOpen])

    const handleConfirm = async () => {
        if (preOrders.length === 0) return
        props.onClose()
        const menuItemsMatrix = preOrders.map(preOrder =>
            (preOrder.pre_order_items || []).map<MenuItem>(preOrderItem => ({
                hash: itemHash({
                    itemId: preOrderItem.item_id,
                    alias: preOrder.alias
                }),
                itemId: preOrderItem.item_id,
                preOrderItemId: preOrderItem.id,
                name: preOrderItem.item.name,
                quantity: preOrderItem.quantity,
                price: preOrderItem.item.price,
                description: preOrderItem?.aditional_information || '',
                categoryItemId: preOrderItem.item.category_item_id,
                categoryName: preOrderItem.item.category_item?.name || '',
                commission: preOrderItem.item.commission
            }))
        )
        savePreOrders(preOrders)
        setMenu(menuItemsMatrix.flat())
    }

    function clickHandler(preOrder: PreOrder) {
        const has = preOrders.find(_preOrder => _preOrder.id === preOrder.id)
        if (has) {
            setPreOrders(
                preOrders.filter(_preOrder => _preOrder.id !== preOrder.id)
            )
        } else {
            setPreOrders([...preOrders, preOrder])
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            size="xl"
            closeOnOverlayClick
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="2xl" fontWeight="bold">
                    Pre-pedidos
                </ModalHeader>
                <ModalBody>
                    <GridBookings
                        preOrders={preOrders}
                        onClick={clickHandler}
                        bookingId={props.bookingId}
                    />
                    <Flex
                        w="full"
                        my="5"
                        gridGap={2}
                        justifyContent="space-between"
                    >
                        <Button
                            _hover={{}}
                            h="60px"
                            w="180px"
                            borderWidth="1px"
                            borderColor="#434343"
                            color="#434343"
                            bg="white"
                            onClick={props.onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            h="60px"
                            w="180px"
                            color="white"
                            bg="orange"
                            _hover={{}}
                            onClick={handleConfirm}
                            autoFocus
                        >
                            Confirmar
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
