import { Box, Flex, Icon, Select, Text } from '@chakra-ui/react'
import ArrowLeft from '../../components/Icon/ArrowLeft'
import ArrowRight from '../../components/Icon/ArrowRight'
import { NumbProps, PaginationProps, PropsArrow } from './types'
import colors from '../../config/theme/colors'

export default function Pagination(props: PaginationProps) {
    return (
        <Flex
            bg="white"
            border="2px solid"
            borderColor="base-3"
            marginTop="5"
            borderRadius="lg"
            justifyContent="space-between"
            px={6}
            py={1}
        >
            <Flex alignItems="center">
                <Text fontSize="sm" mr={2}>
                    Mostrando
                </Text>
                <Select
                    size="xs"
                    value={props.limit}
                    onChange={ev => props.onLimit(Number(ev.target.value))}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </Select>
            </Flex>
            <Box display="flex" justifyContent="flex-end">
                <Arrow
                    orientation="left"
                    active={props.page > 1}
                    onClick={() => props.onPage(props.page - 1)}
                />
                <Box
                    bg="white"
                    display="flex"
                    marginLeft={2}
                    rounded="lg"
                    alignItems="center"
                >
                    {Array.from(Array(props.pages)).map((_, i) => (
                        <Numb
                            key={i}
                            value={i + 1}
                            active={props.page === i + 1}
                            onClick={() => props.onPage(i + 1)}
                        />
                    ))}
                </Box>
                <Arrow
                    orientation="right"
                    active={props.page < props.pages}
                    onClick={() => props.onPage(props.page + 1)}
                />
            </Box>
        </Flex>
    )
}

function Numb({ active, value, onClick }: NumbProps) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            cursor={active ? 'display' : 'pointer'}
            w="27px"
            h="27px"
            onClick={onClick}
            bg={active ? '#E5E7EB' : colors.white}
            color={colors.dark}
            rounded="full"
            fontSize="sm"
        >
            {value}
        </Box>
    )
}

function Arrow({ orientation, active, onClick }: PropsArrow) {
    return (
        <Box
            w="35px"
            h="35px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="full"
            onClick={onClick}
            opacity={active ? '1' : '0.5'}
            cursor={active ? 'pointer' : 'auto'}
        >
            {orientation === 'left' && (
                <Icon as={ArrowLeft} fill={colors.dark} fontSize="sm" />
            )}
            {orientation === 'right' && (
                <Icon as={ArrowRight} fill={colors.dark} fontSize="sm" />
            )}
        </Box>
    )
}
