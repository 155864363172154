import { useState } from 'react'
import {
    ItemData,
    MenuContextInterface,
    MenuItem
} from '../context/MenuContext'
import itemHash from '../utilities/item-hash'

export default function useMenuProviderValues(
    defaultInitialMenu: MenuItem[] = []
): MenuContextInterface {
    const [initialMenu, setInitialMenu] = useState<MenuItem[]>(
        () => defaultInitialMenu
    )
    const [menu, setMenu] = useState<MenuItem[]>(() => defaultInitialMenu)

    function init(_menu: MenuItem[]) {
        setInitialMenu(_menu)
        setMenu(_menu)
    }

    function addItem(itemData: ItemData, quantity = 1) {
        const hash = itemHash({
            itemId: itemData.id
        })
        const item = menu.find(_item => _item.hash === hash)

        if (item) {
            setMenu(
                menu.map(_item => {
                    if (_item.hash === item.hash) {
                        /**
                         * Cuando su cantidad es valor a cero, quiere decir que está eliminado,
                         * solo que aún se mantiene registrado en el estado.
                         */
                        const wasRemoved = _item.quantity === 0
                        return {
                            ..._item,
                            description: wasRemoved ? '' : _item.description,
                            price: wasRemoved ? itemData.price : _item.price,
                            quantity: _item.quantity + quantity
                        }
                    }
                    return _item
                })
            )
            return
        }

        setMenu([
            ...menu,
            {
                hash,
                itemId: itemData.id,
                name: itemData.name,
                quantity,
                price: itemData.price,
                description: '',
                categoryName: itemData.category_item.name,
                categoryItemId: itemData.category_item_id,
                commission: itemData.commission
            }
        ])
    }

    function updateQuantity(itemIdOrHash: number | string, quantity: number) {
        setMenu(_menu =>
            _menu.map(item => {
                const inspect =
                    (typeof itemIdOrHash === 'number' &&
                        itemIdOrHash === item.itemId) ||
                    itemIdOrHash === item.hash
                if (inspect) {
                    return {
                        ...item,
                        quantity
                    }
                }

                return item
            })
        )
    }

    function getQuantity(itemId: number) {
        return menu
            .filter(menuItem => menuItem.itemId === itemId)
            .reduce((n, menuItem) => menuItem.quantity + n, 0)
    }

    function updatePrice(itemIdOrHash: number | string, price: number) {
        setMenu(_menu =>
            _menu.map(item => {
                const inspect =
                    (typeof itemIdOrHash === 'number' &&
                        itemIdOrHash === item.itemId) ||
                    itemIdOrHash === item.hash
                if (inspect) {
                    return {
                        ...item,
                        price
                    }
                }

                return item
            })
        )
    }

    function updateDescription(
        itemIdOrHash: number | string,
        description: string
    ) {
        setMenu(_menu =>
            _menu.map(item => {
                const inspect =
                    (typeof itemIdOrHash === 'number' &&
                        itemIdOrHash === item.itemId) ||
                    itemIdOrHash === item.hash
                if (inspect) {
                    return {
                        ...item,
                        description
                    }
                }

                return item
            })
        )
    }

    function clear() {
        setMenu(menu.map(item => ({ ...item, quantity: 0 })))
    }

    function getPriceTotal() {
        return menu.reduce((n, item) => n + item.price * item.quantity, 0)
    }

    return {
        init,
        initialMenu,
        menu,
        addItem,
        updateQuantity,
        getQuantity,
        updatePrice,
        clear,
        getPriceTotal,
        updateDescription,
        setMenu
    }
}
