import Check from '../Icon/Check'
import Printer from '../Icon/Printer'
import RoomService from '../Icon/RoomService'
import Social from '../Icon/Social'
import DashBoardIcon from '../Icon/DashBoardIcon'
import DraftLine from '../Icon/DraftLine'
import Description from '../Icon/Description'
import Account from '../Icon/Account'
import Receipt from '../Icon/Receipt'
import Transfer from '../Icon/Transfer'
import Table from '../Icon/Table'
import Grid from '../Icon/Grid'

const dataLinks = [
    {
        label: 'Dashboard',
        path: '/dashboard',
        Icon: DashBoardIcon
    },
    {
        label: 'Gestión de productos',
        path: '/cards',
        Icon: RoomService
    },
    {
        label: 'Gestión de usuarios',
        path: '/users',
        Icon: Social
    },
    {
        label: 'Reservas',
        path: '/bookings',
        Icon: Grid
    },
    {
        label: 'Grupos turísticos',
        path: '/tourist_groups',
        Icon: Grid
    },
    {
        label: 'Inventario',
        path: '/inventories',
        Icon: Transfer
    },
    {
        label: 'Gestión de Mesas',
        path: '/tables',
        Icon: Table
    },
    {
        label: 'Gestión de almacén',
        path: '/warehouses',
        Icon: DraftLine
    },
    {
        label: 'Declaración SUNAT',
        path: '/statement',
        Icon: Check
    },
    {
        label: 'Gestión de Impresoras',
        path: '/printerMachines',
        Icon: Printer
    },
    {
        label: 'Reportes',
        path: '/reports',
        Icon: Description
    },
    {
        label: 'Cuentas por cobrar',
        path: '/pendingAccounts',
        Icon: Account
    },
    {
        label: 'Facturador',
        path: '/generateVoucher',
        Icon: Receipt
    }
]

export default dataLinks
