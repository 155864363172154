import { Box } from '@chakra-ui/react'
import { Route } from 'react-router-dom'
import CheckIn from '../components/Icon/CheckIn'
import PageNotFound from '../components/PageNotFound'
import routing from '../config/routing'
import RoutesContext from '../context/RoutesContext'
import useSession from '../hooks/useSession'
import ByTables from '../pages/ByTables'
import Cards from '../pages/Cards'
import CashRegister from '../pages/CashRegister'
import ConfirmOrder from '../pages/ConfirmOrder'
import Login from '../pages/Login'
import PrinterMachines from '../pages/PrinterMachines'
import QuickSale from '../pages/QuickSale'
import ReportReceipts from '../pages/ReportReceipts'
import Reports from '../pages/Reports'
import Sales from '../pages/Sales'
import Statement from '../pages/Statement'
import Tables from '../pages/Tables'
import Users from '../pages/Users'
import Dashboard from '../pages/Dashboard'
import Warehouses from '../pages/Warehouses'
import SplitPayment from '../pages/SplitPayments'
import TouristGroups from '../pages/TouristGroups'
import Bookings from '../pages/Bookings'
import PreOrders from '../pages/PreOrders'
import QuickSaleBy from '../pages/QuickSaleBy'
import PendingAccounts from '../pages/PendingAccounts'
import GenerateVoucher from '../pages/GenerateVoucher'
import MoveProducts from '../pages/MoveProducts'
import OrderStatus from '../pages/OrderStatus'
import PayCommissions from '../pages/PayCommissions'
import ReportBookings from '../pages/ReportBookings'
import Inventories from '../pages/Inventories'
import ReportBusinessLine from '../pages/ReportBusinessLine'
import Deliveries from '../pages/Deliveries'
import DeliveryCustomers from '../pages/DeliveryCustomers'
import DeliveryOrder from '../pages/DeliveryOrder'
import UpdatePayments from '../pages/UpdatePayments'

function ListRoutes() {
    return (
        <>
            <Page Component={Login} path="/" access="public" />
            <Page Component={QuickSale} path="/quick_sale" />
            <Page Component={Cards} path="/cards" access="private" />
            <Page
                Component={CheckIn}
                path="/checkin/:order_id"
                access="private"
            />
            <Page Component={Users} path="/users" access="private" />
            <Page Component={Tables} path="/tables" access="private" />
            <Page
                Component={TouristGroups}
                path="/tourist_groups"
                access="private"
            />
            <Page Component={Bookings} path="/bookings" access="private" />
            <Page Component={ConfirmOrder} path="/confirm" access="private" />
            <Page Component={CashRegister} path="/cash_register" />
            <Page Component={Sales} path="/sales" />
            <Page
                Component={UpdatePayments}
                path="/sales/:order_payment_id/update_payments"
            />
            <Page Component={Statement} path="/statement" />
            <Page Component={ByTables} path="/by_tables" />
            <Page
                Component={QuickSale}
                path="/by_tables/:table_id/quick_sale"
            />
            <Page
                Component={ConfirmOrder}
                path="/orders/:order_id/confirm"
                access="private"
            />
            <Page
                Component={PrinterMachines}
                path="/printerMachines"
                access="private"
            />
            <Page Component={Reports} path="/reports" access="private" />
            <Page
                Component={ReportReceipts}
                path="/reports/receipts"
                access="private"
            />
            <Page Component={Dashboard} path="/dashboard" access="private" />
            <Page Component={Warehouses} path="/warehouses" access="private" />
            <Page
                Component={SplitPayment}
                path="/orders/:order_id/confirm/split"
                access="private"
            />
            <Page
                Component={ConfirmOrder}
                path="/orders/:order_id/confirm/split/:account_id"
                access="private"
            />
            <Page Component={PreOrders} path="/pre_orders" />
            <Page Component={QuickSale} path="/pre_order" />
            <Page
                Component={QuickSale}
                access="private"
                path="/pre_order/:pre_order_id"
            />
            <Page
                Component={QuickSaleBy}
                // access="private"
                path="/quick_sale_by/:by"
            />
            <Page
                Component={PendingAccounts}
                path="/pendingAccounts"
                access="private"
            />
            <Page
                Component={GenerateVoucher}
                path="/generateVoucher"
                access="private"
            />
            <Page
                Component={MoveProducts}
                path="/orders/:order_id/move_products"
                access="private"
            />
            <Page Component={OrderStatus} path="/order_status" />
            <Page Component={PayCommissions} path="/pay_commissions" />
            <Page Component={ReportBookings} path="/reports/commissions" />
            <Page
                Component={Inventories}
                path="/inventories"
                access="private"
            />
            <Page
                Component={ReportBusinessLine}
                path="/reports/business_line"
                access="private"
            />
            <Page Component={Deliveries} path="/deliveries" access="private" />
            <Page
                Component={DeliveryCustomers}
                path="/delivery/customers"
                access="private"
            />
            <Page
                Component={DeliveryCustomers}
                path="/deliveries/:delivery_id/customers"
                access="private"
            />
            <Page
                Component={DeliveryOrder}
                path="/delivery/order"
                access="private"
            />
        </>
    )
}

export default function Routes() {
    const { getAccessRoutes } = useSession()
    const routesData = routing()
        .map(route => ({
            ...route,
            mode: route.mode === undefined ? 'neutro' : route.mode
        }))
        .filter(route => !!getAccessRoutes().find(ar => ar === route.path))

    return (
        <RoutesContext.Provider value={{ routes: routesData }}>
            <Box w="full">
                <ListRoutes />
                <Route path="*">
                    <PageNotFound />
                </Route>
            </Box>
        </RoutesContext.Provider>
    )
}

function Page({ Component, access = 'neutro', path }: PageProps) {
    const { isLogged } = useSession()

    if (
        (access === 'private' && isLogged) ||
        (access === 'public' && !isLogged) ||
        access === 'neutro'
    ) {
        return (
            <Route path={path} exact>
                <Component />
            </Route>
        )
    }

    return <></>
}

interface PageProps {
    Component: (...props: any) => JSX.Element
    access?: 'private' | 'public' | 'neutro'
    path: string
}
