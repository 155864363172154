import { useState } from 'react'
import { MatrixItem } from '../types'
import { storagePage } from '../storage'
import { put } from '../../../lib/http'
import { useToast } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'

export default function useMoveProducts() {
    const { order_id } = useParams<{ order_id?: string }>()
    const toast = useToast()
    const { push } = useHistory()
    const [items, setItems] = useState<MatrixItem[]>([])

    const handleOnChange = (e: any, item_to_move: MatrixItem) => {
        if (e.target.checked) {
            setItems([...items, item_to_move])
            storagePage.items_to_move.push({
                id: item_to_move.id,
                quantity: item_to_move.quantity
            })
        } else {
            items.splice(items.indexOf(e.target.value), 1)
            setItems([...items])
            storagePage.items_to_move.splice(
                storagePage.items_to_move.indexOf(
                    {
                        id: item_to_move.id,
                        quantity: item_to_move.quantity
                    },
                    1
                )
            )
        }
    }

    async function confirmMovement() {
        if (storagePage.items_to_move.length < 1) {
            toast({
                title: 'Debe seleccionar productos para mover',
                position: 'top',
                isClosable: true,
                status: 'error'
            })
            return
        }
        if (storagePage.table_move_id == 0) {
            toast({
                title: 'Debe seleccionar una mesa destino',
                position: 'top',
                isClosable: true,
                status: 'error'
            })
            return
        }
        const { error } = await put(`/api/orders/${order_id}/move_products`, {
            current_table_id: storagePage.table_id,
            new_table_id: storagePage.table_move_id,
            order_items: storagePage.items_to_move
        })
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                isClosable: true,
                status: 'error'
            })
        } else {
            push('/by_tables')
            toast({
                title: 'Los productos cambiaron de mesa',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
        }
    }

    return {
        handleOnChange,
        items,
        confirmMovement
    }
}
