import { useHistory, useLocation } from 'react-router'
import { Link as ChakraLink, Icon as RectIcon } from '@chakra-ui/react'
import colors from '../../config/theme/colors'

interface PropsLink {
    label: string
    path: string
    Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export default function Link({ path, label, Icon }: PropsLink) {
    const { push } = useHistory()
    const { pathname } = useLocation()
    const current = path === pathname

    return (
        <ChakraLink
            mb={2}
            paddingX="5"
            display="flex"
            alignItems="center"
            w="full"
            {...(current ? { bg: 'white', color: 'black' } : {})}
            h={10}
            onClick={() => push(path)}
            _hover={{ textDecoration: 'none' }}
            whiteSpace="nowrap"
            fontSize={{ base: '14px', xl: '16px' }}
        >
            {Icon && (
                <RectIcon
                    as={Icon}
                    fill={current ? colors.black : colors.white}
                    mr={4}
                    fontSize={20}
                />
            )}
            {label}
        </ChakraLink>
    )
}
