import { Tr, Td, Image } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listItem } from '../storage'
import DeleteItem from './DeleteItem'
import Edition from './Edition'
import DefaultPlate from '../../../assets/default-plate.png'
import EditionStock from './EditionStock'

export default function Letter() {
    return (
        <List
            control={listItem}
            tbody={(item, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        <Image
                            boxSize="50px"
                            src={item.image_url || DefaultPlate}
                            alt={item.name}
                            objectFit="cover"
                            ml="auto"
                        />
                    </Td>
                    <Td textAlign="center">{item.name}</Td>
                    <Td textAlign="center">{item.category_item.name}</Td>
                    <Td textAlign="center">S/{item.price}</Td>
                    <Td textAlign="center">
                        {item.has_inventory_control ? item.stock : '-'}
                    </Td>
                    <Td textAlign="center">
                        <EditionStock item={item} />
                        <Edition item={item} />
                        <DeleteItem item={item} />
                    </Td>
                </Tr>
            )}
        />
    )
}
