import { Box, Center, Flex, Text } from '@chakra-ui/react'
import { ItemBoxProps } from './types'
import colors from '../../../../config/theme/colors'
import { memo } from 'react'

function BoxItem({
    name,
    price,
    imageUrl,
    hasStock,
    bg,
    quantity,
    onClick,
    hasInventoryControl,
    stock
}: ItemBoxProps) {
    return (
        <Flex
            bg={bg}
            borderRadius="lg"
            position="relative"
            cursor="pointer"
            h={{
                sm: '130px',
                xl: '160px'
            }}
            p={3}
            color="white"
            flexDirection="column"
            justifyContent="space-between"
            pointerEvents={hasStock ? 'auto' : 'none'}
            opacity="initial"
            onClick={onClick}
            userSelect="none"
        >
            {quantity > 0 && (
                <Center
                    position="absolute"
                    right={'8px'}
                    top={'10px'}
                    color={colors.dark}
                    bg={colors.white}
                    borderRadius="50%"
                    w={'25px'}
                    h={'25px'}
                    lineHeight={1}
                    fontSize={14}
                    zIndex="1"
                >
                    {quantity}
                </Center>
            )}

            {imageUrl && (
                <Box
                    bg={`linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${imageUrl}) ,0, center/cover, no-repeat`}
                    h="full"
                    w="full"
                    borderRadius="10px"
                    position="absolute"
                    backgroundSize="cover"
                    left="0"
                    top="0"
                />
            )}
            <Text
                position="relative"
                fontWeight="semibold"
                fontSize={{
                    sm: 'sm',
                    xl: 'lg'
                }}
                marginRight="20px"
                textDecorationLine={hasStock ? 'none' : 'line-through'}
            >
                {name}
            </Text>
            <Flex align="flex-end" justify="space-between" gap="2">
                <Text position="relative" fontSize="md">
                    S/ {price.toFixed(2)}
                </Text>
                {hasInventoryControl && (
                    <Text fontSize="xs">Quedan {stock}</Text>
                )}
            </Flex>
        </Flex>
    )
}

export default memo(BoxItem)
