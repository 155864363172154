import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import useModalEmit from '../../hooks/useModalEmit'
import { ModalEmitProps } from '../../types'
import Content from './Content'

export default function ModalEmit({
    element,
    orderPaymentId,
    client,
    amount,
    onDone,
    isCredit
}: ModalEmitProps) {
    const {
        submitHandler,
        setOpen,
        isOpen,
        currentClient,
        setClient,
        helper,
        isLoading,
        setDate,
        date,
        setSerie,
        serie
    } = useModalEmit({
        orderPaymentId,
        client,
        amount,
        onDone,
        isCredit
    })

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader>Emitir comprobante</ModalHeader>
                    <ModalBody pb={6}>
                        <Content
                            onSubmit={submitHandler}
                            client={currentClient}
                            setClient={setClient}
                            helper={helper}
                            isCredit={isCredit}
                            setDate={setDate}
                            date={date}
                            setSerie={setSerie}
                            serie={serie}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            w="36"
                            onClick={() => setOpen(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="alo-green:lg-md"
                            w="36"
                            ml={3}
                            type="submit"
                            form="form-emit"
                            isLoading={isLoading}
                        >
                            Emitir
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
