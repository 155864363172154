import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useGet } from '../../../../hooks/useHttp'
import Driver from '../../../../models/driver'
import usePreOrder from '../../hooks/usePreOrder'
import GuideItem from './GuideItem'
import { ModalLocalGuideProps } from './types'

export default function ModalLcalGuide({
    isOpen,
    onClose
}: ModalLocalGuideProps) {
    const { setDriver, driver: driverSaved } = usePreOrder()
    const { data } = useGet<Driver[]>(
        '/api/drivers?page=1&order=asc&sort=id&search=',
        []
    )
    const [driverId, setDriverId] = useState(0)

    function confirmHandler() {
        if (!driverId) return
        const driver = data.result.find(_driver => _driver.id === driverId)
        if (driver) {
            setDriver(driver)
        }
        onClose()
    }

    useEffect(() => {
        if (!isOpen) return
        if (driverSaved) {
            setDriverId(driverSaved.id)
        } else {
            setDriverId(0)
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="2xl" fontWeight="bold">
                    Guías locales
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SimpleGrid columns={[2, 2, 3]} spacing={3}>
                        {data.result.map(driver => (
                            <GuideItem
                                key={driver.id}
                                isChecked={driverId === driver.id}
                                label={driver.name}
                                onClick={() => setDriverId(driver.id)}
                            />
                        ))}
                    </SimpleGrid>
                    <Flex
                        w="full"
                        py="5"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <Button
                            _hover={{}}
                            h="60px"
                            w="180px"
                            borderWidth="1px"
                            borderColor="#434343"
                            color="#434343"
                            bg="white"
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            h="60px"
                            w="180px"
                            color="white"
                            bg="orange"
                            _hover={{}}
                            onClick={confirmHandler}
                            isDisabled={!driverId}
                        >
                            Confirmar
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
