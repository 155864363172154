import { useState } from 'react'

export default function useTab(defaultValue: string) {
    const [value, setValue] = useState(() => defaultValue)
    function onTab(nextValue: string) {
        setValue(nextValue)
    }

    return {
        value,
        onTab
    }
}
