import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import CategoryItem from '../../models/category_item'
import Feature from '../../models/feature'
import Item from '../../models/item'
import PreparationDestiny from '../../models/preparation_destiny'
// import PrinterMachine from '../../models/printer_machine'
import Supply from '../../models/supply'
import TypeMeasure from '../../models/type_measure'
import { FormDestiny, ListCategory } from './types'

export const listCategory = new ControlList<ListCategory>({
    path: '/api/category_items',
    theads: ['Nombre', 'Destino de preparación', 'Orden', 'Acciones'], // omited
    removeLimit: true
})

listCategory.setQuery('sort', 'order')

export const formCategory = new FormEvent({
    name: '',
    printer_id: ''
})

export const listDestiny = new ControlList<PreparationDestiny>({
    path: '/api/preparation_destinations',
    theads: ['Nombre', 'Tipo de conexión', 'IP', 'Acciones']
})

export const formDestiny = new FormEvent<FormDestiny>({
    name: '',
    ip: '',
    connectionType: ''
})

export const listItem = new ControlList<Item & { category_item: CategoryItem }>(
    {
        path: '/api/items',
        theads: ['', 'Nombre', 'Categoría', 'Precio', 'Stock', 'Acciones']
    }
)

export const listSupply = new ControlList<
    Supply & {
        type_measure: TypeMeasure
    }
>({
    path: '/api/supplies',
    theads: ['#', 'Nombre', 'Unidad', 'Stock', 'Acciones']
})

export const listFeature = new ControlList<Feature>({
    path: '/api/features',
    theads: ['#', 'Nombre', 'Cantidad de subcaracteristicas', 'Acciones']
})

export const formItem = new FormEvent<any>({
    id: '',
    name: '',
    image_url: '',
    price: '',
    category_id: '',
    minimal_stock: '0',
    has_inventory_control: false,
    has_features: false,
    features: [],
    commission: '',
    has_commission: false
})

export const formFeature = new FormEvent<any>({
    name: '',
    sub_features: [
        {
            name: '',
            supply_id: '',
            price: '',
            quantity: ''
        }
    ]
})

export const formSupply = new FormEvent({
    id: '',
    name: '',
    has_inventory_control: false,
    stock: '',
    type_measure_id: '',
    default_visual_measure_id: ''
})

export const formEditionStock = new FormEvent({
    id: '',
    name: '',
    actual_stock: '',
    stock: '',
    type_movement_id: '',
    quantity: '',
    comment: ''
})
