import useSubmit from '../../hooks/useSubmit'
import modal from '../../lib/modal'
import PrinterMachine from '../../models/printer_machine'
import FormPrinterMachine from './FormPrinterMachine'
import { controlList, formPrinterMachine } from './storage'

export default function useDestiny(printerMachine?: PrinterMachine) {
    const { validation, submit, observer } = useSubmit(formPrinterMachine, {
        path: printerMachine
            ? `/api/printer_machines/${printerMachine.id}`
            : '/api/printer_machines',
        method: printerMachine ? 'put' : 'post',
        done: () => controlList.load()
    })

    validation((v, f) => {
        v.name.isEmpty('Es requerido el nombre')
        v.type_printer.isEmpty('Seleccione un tipo de conexión')
        if (f.type_printer === '1') {
            v.ip.isIP('La IP no es válida').isEmpty('Es requerido la IP')
        }
        // if (f.type_printer === '2') {
        //     v.device_name.isEmpty('Es requerido el nombre del dispositivo')
        // }
    })

    observer(f => ({
        name: f.name,
        type_printer: Number(f.type_printer),
        ip: f.type_printer === '1' ? f.ip : null
    }))

    function openModal() {
        if (printerMachine) {
            formPrinterMachine.store({
                name: printerMachine.name,
                ip: printerMachine.ip_printer?.ip || '',
                type_printer: printerMachine.type_printer.toString()
            })
            formPrinterMachine.init(formPrinterMachine.store)
        } else {
            formPrinterMachine.init()
        }
        modal(FormPrinterMachine, {
            title: printerMachine ? 'Editar impresora' : 'Nueva impresora',
            onSubmit: submit
        })
    }

    return openModal
}
