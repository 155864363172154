import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Tr, Td, Box, Button } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import useInitial from './useInitial'
import useForm from './useForm'
import Edition from './Edition'
import DeletePrinterMachine from './DeletePrinterMachine'

export default function PrinterMachines() {
    const openModal = useForm()

    useInitial()
    return (
        <Sidebar>
            <SimpleNav title="Gestión de Impresoras" />
            <Box marginBottom="5" display="flex" justifyContent="flex-end">
                <Button
                    variant="primary:md-xl"
                    onClick={openModal}
                    display={{ base: 'none', md: 'block' }}
                >
                    Nueva impresora
                </Button>
                <Button
                    variant="primary:md-xl"
                    onClick={openModal}
                    display={{ base: 'block', md: 'none' }}
                >
                    + Nuevo
                </Button>
            </Box>
            <List
                control={controlList}
                tbody={(_printerMachine, e) => (
                    <Tr key={e}>
                        <Td>{_printerMachine.name}</Td>
                        <Td textAlign="center">
                            {_printerMachine.type_printer === 1
                                ? 'Por IP'
                                : 'Por USB'}
                        </Td>
                        <Td>{_printerMachine.ip_printer?.ip || '-'}</Td>
                        <Td>
                            <Edition printerMachine={_printerMachine} />
                            <DeletePrinterMachine
                                printerMachine={_printerMachine}
                            />
                        </Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
